<template>
  <v-container full-height fluid>
    <v-row align="center" justify="center">
      <v-col md="4">
        <v-textarea class="mt-5" filled label="Password" v-model="password"></v-textarea>
      </v-col>
      <v-col md="4">
        <v-textarea class="mt-5" filled label="Secret" v-model="secret"></v-textarea>
      </v-col>
      <v-col md="4">
        <v-textarea class="mt-5" filled label="Hash" v-model="hash"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
     <v-col md="12">
        <v-btn class="float-right" color="primary" @click="decrypt">Decrypt</v-btn>
        <v-btn color="primary" @click="encrypt">Encrypt</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import  CryptoJS from 'crypto-js';
export default {
    data() {
        return {
            password: '',
            secret: '',
            hash: ''
        }
    },
    methods:{
        encrypt(){
            const encrypted = CryptoJS.AES.encrypt(this.password, this.secret).toString();
            this.hash = encrypted
        },
        decrypt(){
            const bytes  = CryptoJS.AES.decrypt(this.hash, this.secret);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            this.password = originalText
        }
    }
}
</script>

<style lang="scss" scoped></style>
